<template>
    <div>
        
        <div class="normal-card  small-input-height bg-contcolor">
            <div class="filter-area mb-md-5 mb-3">
                <div class="filter-box">
                    <v-form class="multi-col-validation">
                        <div class="d-flex justify-md-space-between align-end flex-md-nowrap flex-wrap">
                            <div class="flex-item">
                                <div class="d-flex align-end flex-md-nowrap flex-wrap">
                                    <!--<div class="datetimepicker input-outlined">
                                        <v-datetime-picker label="起始日期" v-model="datetime1" outlined dense>
                                            <template slot="dateIcon">
                                                <v-icon>{{ icons.mdiCalendar }}</v-icon>
                                            </template>
                                            <template slot="timeIcon">
                                                <v-icon>{{ icons.mdiClockTimeNineOutline }}</v-icon>
                                            </template>
                                        </v-datetime-picker>
                                    </div>
                                    <div class="datetimepicker input-outlined ms-5">
                                        <v-datetime-picker label="結束日期" v-model="datetime2" outlined dense>
                                            <template slot="dateIcon">
                                                <v-icon>{{ icons.mdiCalendar }}</v-icon>
                                            </template>
                                            <template slot="timeIcon">
                                                <v-icon>{{ icons.mdiClockTimeNineOutline }}</v-icon>
                                            </template>
                                        </v-datetime-picker>
                                    </div>-->
                                    <div class="form-group">
                                        <label class="form-label">時間</label>
                                        <div class="form-fill align-end d-flex">
                                            <date-range-picker
                                                opens="right"
                                                ref="picker"
                                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss',cancelLabel:'取消',applyLabel:'確認' }"
                                                :timePicker="true"
                                                :timePicker24Hour="true"
                                                :showWeekNumbers="false"
                                                :showDropdowns="false"
                                                :autoApply="false"
                                                :ranges="false"
                                                v-model="dateRange"
                                                class="form-control"
                                            >
                                            <template v-slot:input="picker" class="">
                                                    {{ picker.startDate | dateFormat }} - {{ picker.endDate | dateFormat }}
                                                </template>
                                            </date-range-picker>
                                        </div>
                                    </div>
                                    <v-btn class="ml-sm-5 mt-md-0 mt-0 mb-md-0 mb-5 w-sm-auto w-full st" color="primary" @click="searchTimes()">查詢</v-btn>
                                </div>
                            </div>
                            
                        </div>
                        
                    </v-form>
                </div>
            </div>
            <div class="map-area">
                <div class="map-box">
                    <div class="leaflet-area pos-relative full-height">
                        <div id="leafletmap"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiCalendar,mdiClockTimeNineOutline } from '@mdi/js';
import locationIcon from '@/assets/images/all/icon_location.svg';
import locationIcon_red from '@/assets/images/all/icon_location_red.svg';
import locationIcon_yellow from '@/assets/images/all/icon_location_yellow.svg';
import locationIcon_orange from '@/assets/images/all/icon_location_orange.svg';
import locationIcon_purple from '@/assets/images/all/icon_location_purple.svg';
import locationIcon_darkred from '@/assets/images/all/icon_location_darkred.svg';
import locationIcon_epa from '@/assets/images/all/icon_location_epa.svg';
import locationIcon_cusepa_orange from '@/assets/images/all/icon_cusepa_orange.svg';
import locationIcon_gray from '@/assets/images/all/icon_location_gray.svg';
import axios from 'axios';
import config from '@/config.js';

import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

var dataArry=[];
var map;
var marker_basic;
var markerlayerGroup;
var markerlayerClusterGroup;
var markers_pos =[];
var LeafIcon;
var greenIcon,redIcon,yellowIcon,orangeIcon,purpleIcon,darkredIcon,grayIcon,epaIcon,cusepaIcon_orange;
var pm25Heat=[],tvocHeat=[];
var default_latlon;
var geoJsonLayer=null;

export default{
    components: {
        mdiCalendar,
        mdiClockTimeNineOutline,
        DateRangePicker
    },
    name: 'History',
    data: () => ({
        icons: {
            mdiCalendar,
            mdiClockTimeNineOutline
        },
        datetime1:"",
        datetime2:"",
        dateRange: {
          startDate: null,
          endDate: null,
        },
    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(4)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){
        $(function() {
            $(".nav_mainTitle").text("重播");
        });
        var self = this;

        //日期範圍預設現在、3小時前
        let today_def = new Date();
        //console.log(formatDatetime(today_def));
        let default_t2 = new Date(today_def.getTime() - 3 * 60 * 60 * 1000);
        this.dateRange.startDate = formatDatetime2(default_t2);
        this.dateRange.endDate = formatDatetime2(today_def);

        //地圖
        const apiurl=config.geturl(config.API.infostation,{});
        axios.get(apiurl).then(response => {
            var overLatlng;
            var overRadius=200;
            
            map = L.map('leafletmap',{
                timeDimensionControl: true,
                timeDimensionControlOptions: {
                    loopButton: true,
                },
                timeDimension: true
            }).fitWorld();
            map.locate({setView: true, maxZoom: 16});
            map.on('locationfound', function(e){
                var radius = e.accuracy;
                overLatlng = e.latlng;
                overRadius = radius;
                default_latlon = overLatlng;
            });
            //全螢幕
            map.addControl(new L.Control.Fullscreen());
            
            

            
            markerlayerGroup = L.layerGroup().addTo(map);
            var windcwblayerGroup = L.layerGroup().addTo(map);



            //圖層
            var baselayers = {
            '街景': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
            '地景圖': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}')
            };
            //複選圖層
            var overlays = {
                '氣象站':windcwblayerGroup,
                '微型感測器':markerlayerGroup,
            };
            L.control.layers(baselayers, overlays).addTo(map);
            baselayers['街景'].addTo(map);


            LeafIcon = L.Icon.extend({
                options: {
                    iconSize: [15, 15],
                iconAnchor: [0, 0],
                popupAnchor: [0, 0]
                }
            });

            greenIcon = new LeafIcon({iconUrl: locationIcon});
                redIcon = new LeafIcon({iconUrl: locationIcon_red});
                yellowIcon = new LeafIcon({iconUrl: locationIcon_yellow});
                orangeIcon = new LeafIcon({iconUrl: locationIcon_orange});
                purpleIcon = new LeafIcon({iconUrl: locationIcon_purple});
                darkredIcon = new LeafIcon({iconUrl: locationIcon_darkred});
                grayIcon = new LeafIcon({iconUrl: locationIcon_gray});
                epaIcon = new LeafIcon({iconUrl: locationIcon_epa});
                cusepaIcon_orange = new LeafIcon({iconUrl: locationIcon_cusepa_orange});

            L.icon = function (options) {
                return new L.Icon(options);
            };

            //全部出現
        
            dataArry=response.data;

            //一開始就出現所有點
            var i = 0,currTime="";
            $.each(dataArry, function() {
                var iconType=grayIcon;
                    /*if(dataArry[i].pm25 !== "" && dataArry[i].pm25 !== null && typeof(dataArry[i].pm25) !== "undefined"){
                        if(parseFloat(dataArry[i].pm25) <= 15.4){
                            iconType=greenIcon;
                        }else if(parseFloat(dataArry[i].pm25) > 15.4 && parseFloat(dataArry[i].pm25) <= 35.4){
                            iconType=yellowIcon;
                        }else if(parseFloat(dataArry[i].pm25) > 35.4 && parseFloat(dataArry[i].pm25) <= 54.4){
                            iconType=orangeIcon;
                        }else if(parseFloat(dataArry[i].pm25) > 54.4 && parseFloat(dataArry[i].pm25) <= 150.4){
                            iconType=redIcon;
                        }else if(parseFloat(dataArry[i].pm25) > 150.4 && parseFloat(dataArry[i].pm25) <= 250.4){
                            iconType=purpleIcon;
                        }else{
                            iconType=darkredIcon;
                        }
                    }*/

                    if(dataArry[i].lat !== "" && dataArry[i].lat !== null && typeof(dataArry[i].lat) !== "undefined" && dataArry[i].lon !== "" && dataArry[i].lon !== null && typeof(dataArry[i].lon) !== "undefined"){
                        marker_basic = L.marker([dataArry[i].lat,dataArry[i].lon], {icon: iconType,title:dataArry[i].id}).addTo(markerlayerGroup);
                    }

                    /*var mp_co=(dataArry[i].co !== null)?dataArry[i].co:"0";
                    var mp_co2=(dataArry[i].co2 !== null)?dataArry[i].co2:"0";
                    var mp_no2 =(dataArry[i].no2 !== null)?dataArry[i].no2:"0";
                    var mp_o3 =(dataArry[i].o3 !== null)?dataArry[i].o3:"0";
                    var mp_hum=(dataArry[i].hum !== null)?dataArry[i].hum:"0";
                    var mp_nh3 =(dataArry[i].nh3 !== null)?dataArry[i].nh3:"0";
                    var mp_h2s =(dataArry[i].h2s !== null)?dataArry[i].h2s:"0";*/

                    marker_basic.bindPopup("<div class='lc-title'>感測器：" + dataArry[i].device + "</div>");
                    
                    marker_basic.on('mouseover',function(ev) {
                        ev.target.openPopup();
                    });

                    markers_pos.push(marker_basic);
                    markerlayerGroup.addLayer(marker_basic);

                i++;

            });

            //氣象站
            const apiurl_cwbwind=config.geturl(config.API.cwbstation,{});
            axios.get(apiurl_cwbwind).then(response => {
                if(response.data !== "" && response.data.length > 0){

                    var cwbWindData = response.data;
                    $.each(cwbWindData,function(index,cw_item){
                        var wind_d=0;
                        var wind_s = 0;
                        var pos_lat = cw_item.lat;
                        var pos_lon = cw_item.lon;
                        var Micon = L.WindBarb.icon({deg: wind_d, wind_s,pointRadius: 4, strokeLength: 10,fillColor:"#2b85c7",strokeWidth:1});
                            var windbab_marker = L.marker([parseFloat(pos_lat),parseFloat(pos_lon)], {icon: Micon});
                            windbab_marker.bindPopup("<div class='lc-title'>感測器：" + cw_item.name + "</div>")
                            windbab_marker.on('mouseover',function(ev) {
                                ev.target.openPopup();
                            });
                        windcwblayerGroup.addLayer(windbab_marker);

                    });

                }
            })

        })

    },
    computed:{
        myDates() {
            //如果dates沒有值或dates長度是0就回傳空字串
            if (!this.dates || this.dates.length === 0) {
            return "";
            }
            let str;
            //取得第1個日期的年月日
            const [year1, month1, day1] = this.dates[0].split("-");
            //自訂格式"年/月/日"
            str = `${year1}/${month1}/${day1}`;
            //如果有第2個日期就做這個if
            if (this.dates.length > 1) {
            //取得第2個日期的年月日
            const [year2, month2, day2] = this.dates[1].split("-");
            //自訂格式"年/月/日"
            let str2 = `${year2}/${month2}/${day2}`;
            //判斷日期1跟日期2的大小來決定字串擺放順序
            if (this.dates[0] > this.dates[1]) {
                str = `${str2} ~ ${str}`;
            } else if (this.dates[1] > this.dates[0]) {
                str += ` ~ ${str2}`;
            }
            }
            //回傳最後的字串
            return str;
        },
    },
    methods:{
        searchTimes(){
            //先清掉所有 layer
            map.removeLayer(markerlayerGroup);
            if(geoJsonLayer != null){
                map.removeLayer(geoJsonLayer);
                geoJsonLayer.clearLayers();
                geoJsonLayer=null;
            } 

            /*if(map != null){
                map.eachLayer(function (layer) {
                    map.removeLayer(layer);
                });
            }*/
            //重播效果
            var self = this;

            var success=false;
            let ala_msg="";
            if(this.dateRange.startDate != null && this.dateRange.startDate != "" && this.dateRange.endDate != null && this.dateRange.endDate != ""){
                var date1 = new Date(this.dateRange.startDate);
                var date2 = new Date(this.dateRange.endDate);
                
                if(date1 < date2){
                    var timespan_diff = parseInt(date2 - date1)/1000/60/60; //時間差:小時數
                    if(timespan_diff <= 3){
                        success=true;
                    }
                    else{
                        ala_msg = "選取時間不能超過 3 小時";
                    }
                }else{
                    ala_msg = "結束時間不能小於開始時間";
                }
            }

        if(success){


            //所有station
            const api_station_url=config.geturl(config.API.infostation,{});
            axios.get(api_station_url).then(response => {
                dataArry = response.data;
            });


            var sTime = new Date(this.dateRange.startDate);
            var eTime = new Date(this.dateRange.endDate);
            var s_time = sTime.getFullYear() + '-' + (sTime.getMonth() + 1) + '-' + sTime.getDate() + ' ' + sTime.getHours() + ':' + sTime.getMinutes() + ':00';
            var e_time = eTime.getFullYear() + '-' + (eTime.getMonth() + 1) + '-' + eTime.getDate() + ' ' + eTime.getHours() + ':' + eTime.getMinutes() + ':00';

            //地圖(重播)
            const apiurl=config.geturl(config.API.env_allsta_records,{stime:s_time,etime:e_time});
            axios.get(apiurl).then(response => {
                var arry=response.data;
                var new_arry=[];
                if(arry !== "" && arry.length > 0){

                    
                    for (var i = 0; i < arry.length; i++) {
                        //先存值
                        var val_arry=[];
                        var station_arry=[];
                        var latlon_arry=[];

                        let staval = arry[i]["staval_list"];
                        for(let a = 0;a < staval.length;a++){
                            for (var idx_Key2 in staval[a]){
                                let val = staval[a][idx_Key2];
                                    if(val != null){
                                        var station_arry=dataArry.filter(item => item.device ==idx_Key2);
                                        if(station_arry.length > 0){
                                            if(station_arry[0].lon != null && station_arry[0].lon != "" && typeof(station_arry[0].lon) != "undefined" &&station_arry[0].lat != null && station_arry[0].lat != "" && typeof(station_arry[0].lat) != "undefined"){
                                                if(val != null && val !="" && val > 0){
                                                    latlon_arry.push([parseFloat(station_arry[0].lon),parseFloat(station_arry[0].lat)]);
                                                    let staVal = (val != null && val !="")?val:"0";
                                                    val_arry.push(parseFloat(staVal));
                                                }
                                                
                                                
                                            }
                                            
                                            //station_arry.push(idx_Key2);    
                                        }
                                    }
                            }
                        }
                        /*for (var idx_Key in arry[i]) {
                            if(idx_Key != "date"){
                                let staval = arry[i][idx_Key];
                                //for(let a = 0;a<staval.length;a++){
                                    for (var idx_Key2 in staval[i]){
                                        var val = staval[i][idx_Key2];
                                        if(val != null){
                                            var station_arry=dataArry.filter(item => item.device ==idx_Key2);
                                            if(station_arry.length > 0){
                                                if(station_arry[0].lon != null && station_arry[0].lon != "" && typeof(station_arry[0].lon) != "undefined" &&station_arry[0].lat != null && station_arry[0].lat != "" && typeof(station_arry[0].lat) != "undefined"){
                                                    latlon_arry.push([parseFloat(station_arry[0].lon),parseFloat(station_arry[0].lat)]);
                                                    let staVal = (val != null && val !="")?val:"0";
                                                    val_arry.push(parseFloat(staVal));
                                                }
                                                
                                                //station_arry.push(idx_Key2);    
                                            }
                                        }
                                    }
                                //}
                                
                                
                            }
                        }*/

                        var time=arry[i].date;
                        var getTime = new Date(time).getTime();

                        new_arry.push({
                                "type": "Feature",
                                "properties": {
                                "time": getTime,
                                "id": (i+1),
                                "values":val_arry
                                },
                                "geometry": {
                                "type": "GeometryCollection",
                                "geometries": [{
                                    "type": "MultiPoint",
                                    "coordinates": latlon_arry
                                }, ]
                                }

                        });


                    }

                    /*var overLatlng;
                    var overRadius=200;*/
                    L.TimeDimension.Layer.GeoJson.GeometryCollection = L.TimeDimension.Layer.GeoJson.extend({

                        // Do not modify features. Just return the feature if it intersects
                        // the time interval    
                        _getFeatureBetweenDates: function(feature, minTime, maxTime) {
                            var featureStringTimes = this._getFeatureTimes(feature);
                            if (featureStringTimes.length == 0) {
                                return feature;
                            }
                            var featureTimes = [];
                            for (var i = 0, l = featureStringTimes.length; i < l; i++) {
                                var time = featureStringTimes[i]
                                if (typeof time == 'string' || time instanceof String) {
                                    time = Date.parse(time.trim());
                                }
                                featureTimes.push(time);
                            }

                            if (featureTimes[0] > maxTime || featureTimes[l - 1] < minTime) {
                                return null;
                            }
                            return feature;
                        },

                    });

                    L.timeDimension.layer.geoJson.geometryCollection = function(layer, options) {
                        return new L.TimeDimension.Layer.GeoJson.GeometryCollection(layer, options);
                    };


                    /*var map = L.map('leafletmap', {
                        zoom: 8,
                        fullscreenControl: true,
                        timeDimensionControl: true,
                        timeDimensionControlOptions: {
                            loopButton: true,
                        },
                        timeDimension: true
                    }).fitWorld();
                    map.on('locationfound', function(e){
                        var radius = e.accuracy;
                        overLatlng = e.latlng;
                        overRadius = radius;
                        default_latlon = overLatlng;
                    });
                    //全螢幕
                    map.addControl(new L.Control.Fullscreen());
                    markerlayerGroup = L.layerGroup().addTo(map);
                    var windcwblayerGroup = L.layerGroup().addTo(map);
                    //圖層
                    var baselayers = {
                    '街景': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
                    '地景圖': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}')
                    };
                    //複選圖層
                    var overlays = {
                        '氣象站':windcwblayerGroup,
                        '微型感測器':markerlayerGroup,
                    };
                    L.control.layers(baselayers, overlays).addTo(map);
                    baselayers['街景'].addTo(map);*/


                    //var baseLayers = getCommonBaseLayers(map); // see baselayers.js
                    //L.control.layers(baseLayers, {}).addTo(map);


                    var geoJsonFeatures = {
                    "type": "FeatureCollection",
                    "features":new_arry
                    };

                    //console.log(geoJsonFeatures);
                    /*var geoJsonFeatures = {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "properties": {
                        "time": 1330391600000,
                        "id": 1,
                        "values":[13.18,0,0,22]
                        },
                        "geometry": {
                        "type": "GeometryCollection",
                        "geometries": [{
                            "type": "MultiPoint",
                            "coordinates": [
                            [1.8003, 38.9580],
                            [1.7968, 38.9498],
                            [1.7648, 38.9737],
                            [1.7681, 38.9814]
                            ]
                        }, ]
                        }
                    }, {
                        "type": "Feature",
                        "properties": {
                        "time": 1430393200000,
                        "id": 2,
                        "values":[88,50,0]
                        },
                        "geometry": {
                        "type": "GeometryCollection",
                        "geometries": [{
                            "type": "MultiPoint",
                            "coordinates": [
                            [1.7003, 39.9580],
                            [1.6968, 40.9498],
                            [1.7681, 41.9814]
                            ]
                        }, ]
                        }
                    }
                    , {
                        "type": "Feature",
                        "properties": {
                        "time": nowTime,
                        "id": 3,
                        "values":[88,50,0,0,50,0,0,0]
                        },
                        "geometry": {
                        "type": "GeometryCollection",
                        "geometries": [{
                            "type": "MultiPoint",
                            "coordinates": [
                            [1.6003, 38.9580],
                            [1.5968, 38.9498],
                            [1.5648, 38.9737],
                            [1.5681, 38.9814],
                            [1.7003, 38.9580],
                            [1.6968, 38.9498],
                            [1.6648, 38.9737],
                            [1.6681, 38.9814]
                            ]
                        }, ]
                        }
                    }]
                    };*/

                    /*LeafIcon = L.Icon.extend({
                        options: {
                            iconSize: [30, 30],
                        iconAnchor: [0, 0],
                        popupAnchor: [0, 0]
                        }
                    });*/
/*
                    greenIcon = new LeafIcon({iconUrl: locationIcon});
                        redIcon = new LeafIcon({iconUrl: locationIcon_red});
                        yellowIcon = new LeafIcon({iconUrl: locationIcon_yellow});
                        orangeIcon = new LeafIcon({iconUrl: locationIcon_orange});
                        purpleIcon = new LeafIcon({iconUrl: locationIcon_purple});
                        darkredIcon = new LeafIcon({iconUrl: locationIcon_darkred});
                        grayIcon = new LeafIcon({iconUrl: locationIcon_gray});
                        epaIcon = new LeafIcon({iconUrl: locationIcon_epa});
                        cusepaIcon_orange = new LeafIcon({iconUrl: locationIcon_cusepa_orange});
*/
                    // L.icon = function (options) {
                    //     return new L.Icon(options);
                    // };


                    /*var geoJsonLayer = L.geoJSON(geoJsonFeatures);
                    geoJsonLayer.eachLayer(function(layer) {
                        
                        var res_val = layer.feature.properties.values;
                        var all_markers=layer.feature.geometry.geometries[0].coordinates;
                        for(var i=0;i<res_val.length;i++){
                            var val = res_val[i];
                            var iconType=grayIcon;
                            if(val !== "" && val !== null && typeof(val) !== "undefined"){
                                if(parseFloat(val) <= 15.4){
                                    iconType=greenIcon;
                                }else if(parseFloat(val) > 15.4 && parseFloat(val) <= 35.4){
                                    iconType=yellowIcon;
                                }else if(parseFloat(val) > 35.4 && parseFloat(val) <= 54.4){
                                    iconType=orangeIcon;
                                }else if(parseFloat(val) > 54.4 && parseFloat(val) <= 150.4){
                                    iconType=redIcon;
                                }else if(parseFloat(val) > 150.4 && parseFloat(val) <= 250.4){
                                    iconType=purpleIcon;
                                }else{
                                    iconType=darkredIcon;
                                }
                            }
                            for(var j=0;j<all_markers.length;j++){
                                if(i==j){
                                    var pos_lat=all_markers[i][0];
                                    var pos_lon=all_markers[i][1];
                                    marker_basic = L.marker([pos_lat, pos_lon], {
                                        icon: iconType
                                    });
                                    markerlayerGroup.addLayer(marker_basic);
                                }
                            }
                        }
                        
                        
                    });*/


                    console.log(geoJsonFeatures);
                    let layers = L.layerGroup().addTo(map);
                    let coords_ini=0;
                    var ori_id=[];
                    var chkSame=false;
                    geoJsonLayer = L.geoJson(geoJsonFeatures, {
                        pointToLayer: function (feature, latlng) {


                            var res_val = feature.properties.values;
                            var res_id = feature.properties.id;
                           
                            var iconType=grayIcon;
                            var coords = feature.geometry.coordinates;
                            
                            var coords_length=coords.length;



                            let count_corini = coords_ini;
                            
                            if(coords_ini < coords_length){
                                //console.log(res_id + " : " + coords_length + " // " + res_val[coords_ini]);
                                // if(res_id == 23){
                                //     console.log(res_id + " : " + coords_length + " // " + res_val[coords_ini]);
                                //     console.log(res_id + " : " + coords_length);
                                // }
                                //console.log(res_id + " : " + coords_length);



                                let val = res_val[coords_ini];
                                    
                                if(val !== "" && val !== null && typeof(val) !== "undefined"){
                                    if(parseFloat(val) > 0 && parseFloat(val) <= 15.4){
                                        iconType=greenIcon;
                                    }else if(parseFloat(val) > 15.4 && parseFloat(val) <= 35.4){
                                        iconType=yellowIcon;
                                    }else if(parseFloat(val) > 35.4 && parseFloat(val) <= 54.4){
                                        iconType=orangeIcon;
                                    }else if(parseFloat(val) > 54.4 && parseFloat(val) <= 150.4){
                                        iconType=redIcon;
                                    }else if(parseFloat(val) > 150.4 && parseFloat(val) <= 250.4){
                                        iconType=purpleIcon;
                                    }else{
                                        iconType=darkredIcon;
                                    }
                                }

                                // let eachMarker =  L.marker([coords[coords_ini][1],coords[coords_ini][0]], {icon: iconType});
                                // layers.addLayer(eachMarker);


//console.log(res_id + " : " + coords_length + " // " + coords[coords_ini][0]);

                                coords_ini++; 
                                let chkLast = count_corini+1;
                                if(chkLast == coords_length)coords_ini=0;
                            }else{
                                coords_ini=0;
                                
                            }
                            return L.marker([coords[coords_ini][1],coords[coords_ini][0]], {icon: iconType});
                            //return layers;
                            //return L.marker([coords[coords_ini][0],coords[coords_ini][1]], {icon: iconType});
                           
                        }
                        /*style: function(feature) {    
                            return {
                            "color": "#FF0000",
                            "weight": 2,
                            "opacity": 0.4
                            };
                        }*/
                    });



                
                    var geoJsonTimeLayer = L.timeDimension.layer.geoJson.geometryCollection(geoJsonLayer, {
                        updateTimeDimension: true,
                        updateTimeDimensionMode: 'replace',  
                        //duration: 'PT30M',
                        duration: 'PT1S', //持續時間
                    });

                    geoJsonTimeLayer._setAvailableTimes();
                    geoJsonTimeLayer.addTo(map);
                }

            });
        }else{

            alert(ala_msg);
        }
    }
    },
    filters: {
        dateFormat: function (datt_str) {
            return formatDatetime(datt_str);
        }

    }
}

function getCommonBaseLayers(map){
    var osmLayer = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    });
    var bathymetryLayer = L.tileLayer.wms("http://ows.emodnet-bathymetry.eu/wms", {
        layers: 'emodnet:mean_atlas_land',
        format: 'image/png',
        transparent: true,
        attribution: "EMODnet Bathymetry",
        opacity: 0.8
    });
    var coastlinesLayer = L.tileLayer.wms("http://ows.emodnet-bathymetry.eu/wms", {
        layers: 'coastlines',
        format: 'image/png',
        transparent: true,
        attribution: "EMODnet Bathymetry",
        opacity: 0.8
    });
    var bathymetryGroupLayer = L.layerGroup([bathymetryLayer, coastlinesLayer]);    
    bathymetryGroupLayer.addTo(map);
    return {
        "EMODnet Bathymetry": bathymetryGroupLayer,
        "OSM": osmLayer
    };
}

</script>